.expressinfo-dialog .tc-15-rich-dialog-hd strong {
  font-size: 14px;
  margin-bottom: 0; }

.expressinfo-dialog-content {
  border: 1px solid #E2E2E2;
  padding: 10px 20px; }
  .expressinfo-dialog-content ul {
    margin-bottom: 0; }
    .expressinfo-dialog-content ul li {
      padding: 5px 0;
      color: #333;
      font-size: 12px;
      line-height: 20px; }
      .expressinfo-dialog-content ul li span {
        display: inline-block;
        vertical-align: top; }
      .expressinfo-dialog-content ul li span:first-child {
        width: 88px;
        color: #888; }
      .expressinfo-dialog-content ul li span:last-child {
        width: calc(100% - 92px);
        word-wrap: break-word; }
