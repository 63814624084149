.userInfo-dialog .tc-15-rich-dialog-hd strong {
  font-size: 14px;
  margin-bottom: 0; }

.userInfo-dialog-content {
  border: 1px solid #E2E2E2;
  padding: 10px 20px; }
  .userInfo-dialog-content .user-avatarUrl {
    width: 80px;
    height: 80px;
    background: #EEEEEE;
    border: 1px solid #E2E2E2;
    text-align: center;
    line-height: 80px; }
  .userInfo-dialog-content ul {
    margin-bottom: 0; }
    .userInfo-dialog-content ul li {
      padding: 10px 0;
      color: #333;
      font-size: 12px;
      line-height: 17px; }
      .userInfo-dialog-content ul li span {
        display: inline-block;
        vertical-align: top; }
      .userInfo-dialog-content ul li span:first-child {
        width: 88px;
        color: #888; }
      .userInfo-dialog-content ul li span:last-child {
        width: calc(100% - 88px); }
