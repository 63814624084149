.code-info-box {
  width: 100%; }

.tip-text {
  height: 51px;
  line-height: 51px;
  text-align: center;
  color: #888;
  font-family: PingFang-SC-Regular;
  font-size: 12px; }

.code-mian {
  overflow: hidden;
  display: flex; }
  .code-mian .code-mian-one {
    width: 36%;
    max-height: 900px; }
    .code-mian .code-mian-one h4 {
      margin-top: 10px; }
    .code-mian .code-mian-one .code-info {
      height: 90%;
      width: 100%;
      font-family: PingFangSC-;
      display: flex;
      font-size: 12px;
      color: #333333;
      float: left;
      padding: 20px 2%;
      border: 1px solid #ddd;
      margin-bottom: 20px; }
      .code-mian .code-mian-one .code-info ul {
        flex: 1; }
        .code-mian .code-mian-one .code-info ul li {
          padding: 10px 0;
          height: 55px;
          width: 100%; }
          .code-mian .code-mian-one .code-info ul li p {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            white-space: unset; }
            .code-mian .code-mian-one .code-info ul li p span {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              word-break: break-all;
              white-space: unset; }
        .code-mian .code-mian-one .code-info ul li > span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
          white-space: unset; }
      .code-mian .code-mian-one .code-info li > span:first-child {
        width: 128px;
        color: #888888; }
      .code-mian .code-mian-one .code-info .scroll-codeInfo-name {
        display: block;
        margin-bottom: 70px; }
        .code-mian .code-mian-one .code-info .scroll-codeInfo-name .scroll-box {
          max-height: 90px;
          display: inline-block;
          overflow-y: auto;
          display: inline-block;
          padding: 10px; }
  .code-mian .code-mian-two {
    width: 30%;
    margin-left: 2%; }
  .code-mian .code-mian-three {
    width: 15%;
    margin-left: 2%; }
    .code-mian .code-mian-three .code-info1 {
      height: 547px;
      max-width: 700px;
      overflow-y: auto; }

.code-info {
  font-family: PingFangSC-;
  display: flex;
  padding-top: 10px;
  font-size: 12px;
  color: #333333; }
  .code-info > ul {
    flex: 1; }
    .code-info > ul li {
      padding: 10px 0;
      min-height: 38px; }
    .code-info > ul li > span {
      display: block; }
    .code-info > ul li > span:first-child {
      float: left;
      width: 128px;
      color: #888888; }
    .code-info > ul li > span:last-child {
      overflow: hidden;
      width: calc(100% - 200px); }

.table-list {
  width: 50%;
  min-height: 40px;
  line-height: 40px;
  text-align: left;
  border-collapse: collapse;
  margin: 20px auto 0;
  margin-bottom: 20px; }

.table-list,
.table-list tr th,
.table-list tr td {
  border: 1px solid #e9eaec; }

.table-list tr th,
.table-list tr td {
  padding: 0 20px;
  word-break: break-all; }

.AcquisitionInfo-modal .table-list {
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: left;
  border-collapse: collapse;
  margin: 20px auto 0;
  margin-bottom: 20px; }

.AcquisitionInfo-modal .table-list,
.AcquisitionInfo-modal .table-list tr th,
.AcquisitionInfo-modal .table-list tr td {
  border: 1px solid #e9eaec; }

.AcquisitionInfo-modal .table-list tr th,
.AcquisitionInfo-modal .table-list tr td {
  padding: 0 20px;
  word-break: break-all; }
