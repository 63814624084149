.user-query-container {
  width: 100%;
  height: 100%;
  margin: 20px auto; }
  .user-query-container .user-record-box {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    text-align: right; }
    .user-query-container .user-record-box .tc-15-input-text.search-input {
      width: 100% !important; }
    .user-query-container .user-record-box .search-input-block {
      width: 330px; }
      .user-query-container .user-record-box .search-input-block .tc-15-search-words {
        width: 100%; }
    .user-query-container .user-record-box .user-tablelist {
      margin-top: 10px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      border-bottom: none; }
  .user-query-container .user-query-records-list {
    margin-top: 20px;
    padding-top: 12px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2; }
    .user-query-container .user-query-records-list .tc-15-tablist {
      padding-left: 20px; }
    .user-query-container .user-query-records-list .records-list-head {
      padding: 0 20px;
      overflow: hidden; }
      .user-query-container .user-query-records-list .records-list-head .table-title {
        font-size: 12px;
        line-height: 12px;
        color: #888888;
        padding-top: 15px;
        display: inline-block; }
    .user-query-container .user-query-records-list .lottery-record-box {
      padding: 0 20px;
      margin-top: 10px;
      background: #FFFFFF;
      padding-bottom: 20px; }
  .user-query-container .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .user-query-container .moreline-text-ellipsis {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }
  .user-query-container .cursor-handle {
    cursor: pointer; }
